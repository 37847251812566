import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import TitleYellow from './../../assets/images/svg-title-yellow.svg'

export const AbrirConta = styled.section`

  @media ${device.tablet} {
    align-items: center;
    display: flex;
    min-height: 800px;
  }

  @media ${device.desktopLG} {
    min-height: 760px;
  }

  img {
    @media ${device.desktopLG} {
      max-width: 100%;
    }
  }

  .boxed--shadow {
    align-items: initial;
    min-height: 134px;
    margin-bottom: 8px;
    padding-right: 30px;

    @media ${device.tablet} {
      min-height: 167px;
      padding: 20px;
    }

    @media ${device.desktopLG} {
      min-height: 160px;
    }   

    @media ${device.desktopXXL} {
      min-height: 155px;
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }
  }

  h2 {
    &::after {
      content: '';
      background: url(${TitleYellow}) no-repeat;
      height: 53px !important;
      position: absolute;
      right: 17px;
      top: 20px;
      width: 47px !important;

      @media ${device.tablet} {
        margin-left: 30px;
        right: auto;
        top: 0;
      }
    }
  }
`
