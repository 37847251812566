import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CartaoDebito = styled.section`

  .react-multiple-carousel__arrow--right {
    right: 0;
    @media ${device.tablet} {
      right: 0!important;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -11px;
    @media ${device.tablet} {
      left: -10px!important;
    }
  }

  .cartao-personalizado {
    overflow: hidden;

    img {
      width: 363px;
      @media ${device.desktopLG} {
        width: 472px;
        left: -80px !important;
        top: 10px;
        position: relative;
      }
      @media ${device.desktopXL} {
        width: 472px;
        top: 10px;
        position: relative;
        left: -30px !important;
      }
    }
  }

  .svg-title {
    position: absolute;
    right: -60px;
    top: -5px;
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }
    @media ${device.desktopXXL} {
      max-width: 94%;
    }
  }

  .card {
    background-color: white;    
    width: auto;
    margin-right: 10px;
    border-radius: 16px;

    @media ${device.tablet} {
      border-radius: 10px;
    }
  }
`
