import React from 'react'

import Icon from 'src/components/UI/MarkdownIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'

import { CartaoDebito } from './style'

import investAndMakeMoneyPayJSON from '../../assets/data/investAndMakeMoneyPay.json'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsModalOpen: Function;
  setSectionDetails: Function;
}

type ItemProps = {
  icon: string;
  title: string;
  description: string;
}

const InvistaDinheiroSeuFilho = ({ setIsModalOpen, setSectionDetails }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 696, 376, 552,
  ]

  const arrayHeight: number[] = [
    379, 846, 457, 670,
  ]

  return (
    <CartaoDebito id='invista-o-dinheiro-do-seu-filho' className='bg-yellow--extra-dark py-5 pb-md-3'>
      <div className='container mt-lg-5'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-5 offset-lg-0 col-xl-6 order-lg-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra4-kids/image.webp'
              altDescription='Jovem + cartão e imagem App Conta Digital Banco Inter'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-lg-7 col-xl-6 mt-3'>
            <h2 className='fs-32 fs-md-40 text-blue--medium'>
              <span className='d-lg-block'>Invista e faça o dinheiro</span> do seu filho render
            </h2>
            <p className='fs-16 fs-md-18 lh-22 text-blue--medium mb-0 mb-md-2'>
              <span className='d-lg-block'>Temos uma plataforma completa pra você investir o</span> dinheiro do seu filho e planejar o futuro desde já.
            </p>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
            >
              { investAndMakeMoneyPayJSON.map((item: ItemProps) => (
                <div key={item.title} className='card d-flex h-100 flex-column justify-content-center overflow-hidden mt-0'>
                  <div className='d-flex justify-content-start'>
                    <div className='mr-2'><Icon width='33' height='33' icon={item.icon} directory='v2' /></div>
                    <div><p className='fw-600 fs-21 fs-lg-24 lh-22 text-blue--medium mt-2'>{item.title}</p></div>
                  </div>
                  <div>
                    <p className='fs-14 fs-md-18 lh-24'>{item.description}</p>
                  </div>
                </div>
                ),
              )}
            </DefaultCarousel>
            <div className='col-12 mt-lg-5 d-none d-lg-block pl-lg-0'>
              <button
                onClick={() => {
                  setIsModalOpen(true)
                  setSectionDetails({
                    sectionName: 'Invista e faça o dinheiro do seu filho render',
                    dobra: 'dobra_04',
                  })
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Abrir conta KIDS gratuita',
                    section_name: 'Invista e faça o dinheiro do seu filho render',
                  })
                }}
                className='btn btn--orange fs-14 fw-700 rounded-1'
              >
                Abrir conta KIDS gratuita
              </button>
            </div>
          </div>
          <div className='col-12 mt-4 mt-md-5 d-lg-none'>
            <button
              onClick={() => {
                setIsModalOpen(true)
                setSectionDetails({
                  sectionName: 'Invista e faça o dinheiro do seu filho render',
                  dobra: 'dobra_04',
                })
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Abrir conta KIDS gratuita',
                  section_name: 'Invista e faça o dinheiro do seu filho render',
                })
              }}
              className='btn btn--orange fs-14 fw-700 rounded-1'
            >
              Abrir conta KIDS gratuita
            </button>
          </div>
        </div>
      </div>
    </CartaoDebito>
  )
}

export default InvistaDinheiroSeuFilho
