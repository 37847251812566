import React from 'react'
import Img from 'gatsby-image'
// Components
import HowTo from 'src/components/StructuredData/HowTo'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'

// Style
import { ComoAbrirConta, ImgNumberSize, ImgAppSize } from './style'

// Assets
import comoAbrirContaKids from './../../assets/images/detail-how-to-open-a-kids-account.svg'
import howToOpenKidsAccountJSON from '../../assets/data/howToOpenKidsAccount.json'

// PageContext
import pageContext from '../../pageContext.json'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ItemProps = {
  step: string;
  image: string;
  title: string;
  description: string;
};

const ComoAbrirContaKids = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const howToData = pageContext.structuredData.howTo

  const arrayNumbers: number[] = [
    170, 176, 176, 176,
  ]

  const arrayHeight: number[] = [
    235, 244, 244, 244,
  ]

  return (
    <ComoAbrirConta
      id='como-abrir-uma-conta-kids'
      className='bg-blue--extra-dark py-5 d-flex align-items-center'
    >
      <div className='container'>
        <div className='detail'>
          <img src={comoAbrirContaKids} alt='Conta Kids' />
        </div>
        <div className='row'>
          <div className='col-12 text-left mt-4 mt-lg-3'>
            <h2 className='fs-32 fs-md-40 text-white mt-3 mt-md-1 mt-lg-0'>
              Como abrir uma{' '}
              <span className='d-block d-md-inline'>Conta Kids</span>
            </h2>
            <p className='fs-16 fs-md-18 text-white mb-3 mb-md-5'>
              Veja como é fácil abrir a Conta Kids! É importante dizer que o{' '}
              <span className='d-md-block'>
                menor de idade precisa ter CPF e RG, combinado?
              </span>
            </p>
            <HowTo data={howToData}>
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 3 }}
                xl={{ items: 3 }}
              >
                {howToOpenKidsAccountJSON.map((item: ItemProps) => (
                  <>
                    <div
                      key={item.title}
                      className='d-flex justify-content-between mx-auto col-md-7 col-lg-12 d-lg-none d-xl-flex px-xl-0'
                    >
                      <div>
                        <ImgAppSize>
                          <ImageWebp
                            pathSrc={item.image}
                            altDescription={item.step}
                            arrayNumbers={arrayNumbers}
                            arrayNumbersHeight={arrayHeight}
                          />
                        </ImgAppSize>
                      </div>
                      <div>
                        <div className='mt-1 pl-2 pl-md-0 pt-1 text-left'>
                          <ImgNumberSize className='col-5 col-md-4 col-xl-3 mb-3 pl-0 pl-md-2'>
                            <Img
                              fluid={data[item.step].fluid}
                              alt={item.title}
                            />
                          </ImgNumberSize>
                        </div>
                        <div className='pl-2'>
                          <h4
                            className='fs-24 lh-28 text-white mt-4 mb-2 mb-md-3 mb-lg-2'
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                          <p
                            className='fs-16 fs-md-16 lh-20 text-white'
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-none d-lg-block d-xl-none'>
                      <div key={item.title} className='px-0 mx-0'>
                        <div className='d-flex justify-content-center'>
                          <ImgNumberSize>
                            <Img
                              fluid={data[item.step].fluid}
                              alt={item.title}
                            />
                          </ImgNumberSize>
                        </div>
                        <div className='text-content'>
                          <h4
                            className='fs-24 lh-28 text-white mt-4 mb-2 mb-md-3 mb-lg-2 text-center'
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                          <p
                            className='fs-16 fs-md-16 lh-20 text-white text-center mt-md-3'
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                        <div className='text-lg-center'>
                          <ImgAppSize>
                            <ImageWebp
                              pathSrc={item.image}
                              altDescription={item.step}
                              arrayNumbers={arrayNumbers}
                              arrayNumbersHeight={arrayHeight}
                            />
                          </ImgAppSize>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </DefaultCarousel>
            </HowTo>
          </div>
          <div className='col-12 mt-5 mt-md-5 mt-lg-0 mt-xl-3 mb-md-5'>
            <p className='text-center text-white fs-18 lh-22 mb-0'>
              Quer saber mais sobre como abrir a Conta Kids?
              <a
                className='fw-600 ml-2'
                href='https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/'
                target='_blank'
                rel='noreferrer'
                title='Acessar o nosso blog'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Veja no nosso blog',
                    section_name: 'Como abrir uma Conta Kids',
                    redirect_url: 'https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/',
                  })
                }}
              >
                Veja no nosso blog
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </ComoAbrirConta>
  )
}

export default ComoAbrirContaKids
