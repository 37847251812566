import React from 'react'

// hooks
import useWidth from 'src/hooks/window/useWidth'

// Images
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import PigForm from '../../../../../assets/images/shared/pig-form.svg'
import QrCodeKids from '../assets/images/qrcode-lp-conta-kids.png'

// Styles
import { Container, CloseButton } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const WIDTH_MD = 1024

type modalKidsProps = {
  closeModal: () => void;
  sectionDetails: {
    sectionName: string;
    dobra: string;
  };
}

function ModalKids ({ closeModal, sectionDetails }: modalKidsProps) {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <>
      <Container className='container d-flex justify-content-center align-items-center'>
        <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
          <Close width='24' height='24' color='gray' />
        </CloseButton>
        <div className='row'>
          <div className='col-12 text-center'>
            <img src={PigForm} alt='Imagem de sucesso conta Kids' />
            <h3 className='fs-20 pt-4 mb-2 lh-24 text-gray--800'>Completa, digital e gratuita</h3>
            <p className='fs-16 lh-20 text-gray--600'>
              Para abrir sua Conta Kids, é só baixar o app e clicar em “<strong>Abrir uma Conta Gratuita</strong>”. É rápido e fácil!
            </p>
          </div>
          <div className='col-12 text-center'>
            { windowWidth < WIDTH_MD
              ? (
                <a
                  href='https://bancointer.go.link/criarContaCompleta?adj_t=17uv74ah&adj_deep_link=bancointer%3A%2F%2Flogin&adj_fallback=https%3A%2F%2Fgointer.app%2FcriarContaCompleta&adj_redirect_macos=https%3A%2F%2Fgointer.app%2FcriarContaCompleta&adj_adgroup=contadigital&adj_creative=lp_abertura_kidseyou'
                  target='_blank'
                  rel='noreferrer'
                  className='btn-orange fs-16 rounded-2 mt-3 fw-600'
                  title='Baixe o app'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: sectionDetails.dobra,
                      element_action: 'click button',
                      element_name: 'Baixe o app',
                      section_name: sectionDetails.sectionName,
                      redirect_url: 'https://bancointer.go.link/criarContaCompleta?adj_t=17uv74ah&adj_deep_link=bancointer%3A%2F%2Flogin&adj_fallback=https%3A%2F%2Fgointer.app%2FcriarContaCompleta&adj_redirect_macos=https%3A%2F%2Fgointer.app%2FcriarContaCompleta&adj_adgroup=contadigital&adj_creative=lp_abertura_kidseyou',
                      element_previous: 'Abrir conta KIDS gratuita',
                    })
                  }}
                >
                  Baixe o app
                </a>
                )
              : (
                <div>
                  <p className='fs-16 lh-20 fw-600 text-gray--800 mt-2 mb-4'>
                    Aponte a sua câmera para fazer a leitura do código QR e baixe o App
                  </p>
                  <img src={QrCodeKids} alt='Baixe o app ou acesse sua conta Inter' />
                </div>
              )
            }
          </div>
        </div>
      </Container>
    </>
  )
}

export default ModalKids
