import React from 'react'

import Icon from 'src/components/UI/MarkdownIcon'
import ScrollTo from 'src/components/ScrollTo'
import ImageWebp from 'src/components/ImageWebp'

import { AbrirConta } from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsModalOpen: Function;
  setSectionDetails: Function;
}

const AbrirContaKids = ({ setIsModalOpen, setSectionDetails }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    0, 0, 308, 372,
  ]

  const arrayHeight: number[] = [
    0, 0, 282, 340,
  ]

  return (
    <AbrirConta id='porque-abrir-a-conta-kids' className='bg-green--extra-dark py-5 pt-md-0'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 mb-4 mb-md-5 text-md-center text-lg-left'>
            <h2 className='text-white fs-32 fs-md-40 float-left mr-3'>
              <span className='d-block d-md-inline'>Por que abrir a</span> Conta Kids?
            </h2>
          </div>
          <div className='col-12 col-lg-8'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='boxed--shadow'>
                  <div><Icon width='32' height='32' color='orange--base' icon='products/investimentos/porquinho' directory='v2' /></div>
                  <div className='d-block'>
                    <h4 className='fs-20 fs-md-24 pt-1 text-gray--700 mb-3 lh-22 lh-md-24'>Invista no futuro</h4>
                    <p className='fs-14 text-gray--600 mb-1 lh-18'>Faça o dinheiro do seu filho render mais</p>
                    <ScrollTo
                      to='#invista-o-dinheiro-do-seu-filho'
                      section='dobra_02'
                      sectionName='Por que abrir a Conta Kids?'
                      elementName='Saiba mais + Invista no futuro'
                      className='fs-14 fw-600'
                    >
                      Saiba mais
                    </ScrollTo>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='boxed--shadow'>
                  <div><Icon width='32' height='32' color='orange--base' icon='pagamentos/cards' directory='v2' /></div>
                  <div className='d-block'>
                    <h4 className='fs-20 fs-md-24 pt-1 text-gray--700 mb-3 lh-22 lh-md-24'>Cartão de débito</h4>
                    <p className='fs-14 text-gray--600 mb-1 lh-18'>Mais segurança para seu filho</p>
                    <ScrollTo
                      to='#cartao-de-debito'
                      section='dobra_02'
                      sectionName='Por que abrir a Conta Kids?'
                      elementName='Saiba mais + Cartão de débito'
                      className='fs-14 fw-600'
                    >
                      Saiba mais
                    </ScrollTo>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='boxed--shadow pt-4'>
                  <div><Icon width='32' height='32' color='orange--base' icon='products/cambio/cambio' directory='v2' /></div>
                  <div className='d-block'>
                    <h4 className='fs-20 fs-md-24 pt-1 text-gray--700 mb-3 lh-22 lh-md-24'>Conta de Graça</h4>
                    <p className='fs-14 text-gray--600 mb-1 lh-18'>Sem anuidade e sem complicação</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='boxed--shadow pt-4'>
                  <div><Icon width='32' height='32' color='orange--base' icon='products/credito/credito' directory='v2' /></div>
                  <div className='d-block'>
                    <h4 className='fs-20 fs-md-24 pt-1 text-gray--700 mb-3 lh-22 lh-md-24'>Educação financeira</h4>
                    <p className='fs-14 text-gray--600 mb-1 lh-18'>Ensine o valor do dinheiro desde cedo</p>
                  </div>
                </div>
              </div>
              <div className='col-12 mt-3 mt-md-4'>
                <button
                  onClick={() => {
                    setIsModalOpen(true)
                    setSectionDetails({
                      sectionName: 'Por que abrir a Conta Kids?',
                      dobra: 'dobra_02',
                    })
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      element_action: 'click button',
                      element_name: 'Abrir conta KIDS gratuita',
                      section_name: 'Por que abrir a Conta Kids?',
                    })
                  }} className='btn btn--orange fs-14 fw-700 rounded-1'
                >
                  Abrir conta KIDS gratuita
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4 d-none d-lg-block pr-lg-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-kids-dobra2/image.webp'
              altDescription='Porquinho Conta Digital Kids Banco Inter'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
        </div>
      </div>
    </AbrirConta>
  )
}

export default AbrirContaKids
