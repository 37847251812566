import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, grayscale } from 'src/styles/colors'

export const PerguntasFrequentesKids = styled.section`

  input[type='text'] {
    background-color: #F5F6FA;
    color: ${grayscale['300']};
    ::placeholder {
      color: ${grayscale['300']};
      font-weight: 700;
    }
  }
  .summary-details {
    border-bottom: 1px solid ${white};

    .summary {
      background: transparent;
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;

      .summary-content {
        align-items: center;
        p, span {
          color: #161616;
        }
        .arrow {
          filter: contrast(15%) brightness(207%);
        }
      }
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }
  }
`
