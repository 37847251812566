import React from 'react'

import Breadcrumb from 'src/components/Breadcrumb'
import Icon from 'src/components/UI/MarkdownIcon'
import ScrollTo from 'src/components/ScrollTo'
import ImageWebp from 'src/components/ImageWebp'

import { ContentHeader } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsModalOpen: Function;
  setSectionDetails: Function;
}

const Header = ({ setIsModalOpen, setSectionDetails }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 576, 456, 552,
  ]

  const arrayHeight: number[] = [
    257, 474, 375, 454,
  ]

  return (
    <ContentHeader className='pb-5 d-lg-flex position-relative'>
      <div className='container'>
        <div className='row align-items-center justify-content-lg-between'>
          <div className='col-12 d-lg-none fixed-bread pl-md-3'>
            <Breadcrumb
              sectionName='Conta Kids'
              type='pra você'
              text='Conta Kids'
              link='/pra-voce/conta-digital/conta-kids/'
            />
          </div>
          <div className='col-12 col-md-10 col-lg-6 pt-3 order-md-last offset-xl-1 mt-md-3'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-kids-hero-768-360/image.webp'
              altDescription='Mãe e filha - Conta Digital Kids Banco Inter'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-lg-5'>
            <div className='col-12 d-none d-lg-block fixed-bread pl-lg-1'>
              <Breadcrumb
                sectionName='Conta Kids'
                type='pra você'
                text='Conta Kids'
                link='/pra-voce/conta-digital/conta-kids/'
              />
            </div>
            <div className='mt-3 mt-lg-2'>
              <h1 className='fs-32 fs-md-50 text-purple--base mb-0 mb-md-2 fw-600'>Conta Kids</h1>
              <p className='fs-16 fs-md-20 lh-24 text-gray--light mb-0'>
                Uma conta completa e gratuita. Pra você começar a planejar e a investir no futuro do seu filho, sem preocupação.
              </p>
              <button
                onClick={() => {
                  setIsModalOpen(true)
                  setSectionDetails({
                    sectionName: 'Conta Kids',
                    dobra: 'dobra_01',
                  })
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Abrir conta KIDS gratuita',
                    section_name: 'Conta Kids',
                  })
                }}
                className='btn btn--orange mt-4 fs-14 fw-700 rounded-1'
              >
                Abrir conta KIDS gratuita
              </button>
            </div>
          </div>
        </div>
      </div>
      <ScrollTo
        to='#porque-abrir-a-conta-kids'
        section='dobra_01'
        sectionName='Conta Kids'
        elementName='Conheça as vantagens'
        styles='scroll-link text-center d-none d-md-block'
      >
        <p className='text-gray--light mb-0 text-uppercase fs-14 fs-lg-16 fw-600'>Conheça as vantagens</p>
        <Icon color='orange' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
      </ScrollTo>
    </ContentHeader>
  )
}

export default Header
