import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// Style
import { FaresCard } from './style'

const NaoCobramosTarifas = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row align-items-center pr-xl-1'>
          <div className='col-12 col-lg-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 text-grayscale--500 text-center text-lg-left'>
              <span className='d-lg-block'>Por que não</span> cobramos anuidade?
            </h2>
            <p className='fs-18 lh-22 text-grayscale--500 text-center text-lg-left'>
              Nascemos para fazer diferente. Participamos da vida de milhões de pessoas, reunindo em uma mesma plataforma soluções que fazem a diferença no seu dia a dia.
            </p>
            <p className='fs-18 lh-22 text-grayscale--500 text-center text-lg-left'>
              E por isso, criamos a primeira conta digital e gratuita do Brasil. Como isso é possível? A gente explica.
            </p>
          </div>
          <div className='col-12 col-lg-6 offset-lg-1 mt-md-2'>
            <div className='row'>
              <FaresCard className='col-md-6 col-lg-12 px-2'>
                <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='smartphones' />
                  </div>
                  <div className='pt-md-0'>
                    <h4 className='fs-20 text-grayscale--500 mb-2'>Somos digitais</h4>
                    <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>
                      Como você resolve tudo pela internet, não precisamos de agências. Isso aumenta a nossa eficiência e gera economia.
                    </p>
                  </div>
                </div>
              </FaresCard>
              <FaresCard className='col-md-6 col-lg-12 px-2'>
                <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='services' />
                  </div>
                  <div className='pt-md-0'>
                    <h4 className='fs-20 text-grayscale--500 mb-2'>Temos processos automatizados</h4>
                    <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>
                      Também economizamos com o custo operacional. Criamos processos inteligentes, que reduzem esforço e burocracia.
                    </p>
                  </div>
                </div>
              </FaresCard>
              <FaresCard className='col-md-6 col-lg-12 px-2'>
                <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='light-on' />
                  </div>
                  <div className='pt-md-0'>
                    <h4 className='fs-20 text-grayscale--500 mb-2'>Somos sustentáveis</h4>
                    <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>
                      Como não temos agência e somos digitais, economizamos papel, energia e outros recursos.
                    </p>
                  </div>
                </div>
              </FaresCard>
              <FaresCard className='col-md-6 col-lg-12 px-2'>
                <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='dollar' />
                  </div>
                  <div className='pt-md-0'>
                    <h4 className='fs-20 text-grayscale--500 mb-2'>Ganhamos em escala</h4>
                    <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>
                      Você utiliza sua Conta Digital e outros serviços, fica satisfeito com a gente e nos indica para os seus amigos.
                    </p>
                  </div>
                </div>
              </FaresCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NaoCobramosTarifas
