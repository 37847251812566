import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import ModalKids from './ModalKids/_index'
import { Modal } from 'src/components/Modal'

import Header from './sections/header/_header'
import AbrirContaKids from './sections/abrir-conta-kids/_abrir-conta-kids'
import ComoAbrirContaKids from './sections/como-abrir-conta-kids/_como-abrir-conta-kids'
import InvistaDinheiroSeuFilho from './sections/invista-o-dinheiro-do-seu-filho/_invista-o-dinheiro-do-seu-filho'
import CartaoPesonalizaNomeFilho from './sections/cartao-personalizado-nome-filho/_cartao-personalizado-nome-filho'
import NaoCobramosTarifas from './sections/nao-cobramos-tarifas/_nao-cobramos-tarifas'
import DicasParaInvestirMelhor from './sections/dicas-para-investir-melhor/_dicas-para-investir-melhor'
import PerguntasFrequentes from './sections/perguntas-frequentes/_perguntas-frequentes'

import useDomReady from 'src/hooks/window/useDomReady'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const ContaKids = () => {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ sectionDetails, setSectionDetails ] = useState({
    sectionName: 'Conta kids',
    dobra: 'dobra_01',
  })

  const accountKidsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <ModalKids closeModal={() => setIsModalOpen(false)} sectionDetails={sectionDetails} />
    </Modal>
  )
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {accountKidsModal}
        <Header setIsModalOpen={setIsModalOpen} setSectionDetails={setSectionDetails} />
        <AbrirContaKids setIsModalOpen={setIsModalOpen} setSectionDetails={setSectionDetails} />
        <ComoAbrirContaKids />
        <InvistaDinheiroSeuFilho setIsModalOpen={setIsModalOpen} setSectionDetails={setSectionDetails} />
        <CartaoPesonalizaNomeFilho setIsModalOpen={setIsModalOpen} setSectionDetails={setSectionDetails} />
        <NaoCobramosTarifas />
        <DicasParaInvestirMelhor />
        <PerguntasFrequentes setIsModalOpen={setIsModalOpen} setSectionDetails={setSectionDetails} />
      </Layout>
    </Wrapper>
  )
}

export default ContaKids
