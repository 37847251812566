import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      stepOne: imageSharp(fluid: {originalName: { regex: "/step-number-one/" }}) {
        fluid(maxWidth: 62, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      stepTwo: imageSharp(fluid: {originalName: { regex: "/step-number-two/" }}) {
        fluid(maxWidth: 62, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      stepThree: imageSharp(fluid: {originalName: { regex: "/step-number-three/" }}) {
        fluid(maxWidth: 62, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
