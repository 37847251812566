import React from 'react'
import Faq from 'src/components/Faq/index'

// Style
import { PerguntasFrequentesKids } from './style'

import pageContext from '../../pageContext.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsModalOpen: Function;
  setSectionDetails: Function;

}

const PerguntasFrequentes = ({ setIsModalOpen, setSectionDetails }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <PerguntasFrequentesKids id='perguntas-frequentes' className='bg-yellow--extra-dark py-5'>
      <div className='container'>
        <div className='row pl-lg-3 pr-xl-3'>
          <div className='col-12 text-lg-center mb-4'>
            <h3 className='text-grayscale--500'>Perguntas frequentes</h3>
            <p className='text-grayscale--500 lh-20 lh-md-25'>
              Ainda tem dúvidas? Veja se podemos te ajudar com essas respostas.
              <span className='d-none d-md-inline d-lg-none'> Acesse nossa
                <a className='text-orange--base' href='https://ajuda.bancointer.com.br' target='_blank' rel='noreferrer'><u>Central de Ajuda</u></a> pra saber mais.
              </span>
            </p>
          </div>
          <Faq
            id='conta-kids'
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='summary-content px-0'
            answerData={pageContext.structuredData.faq}
            questionColor='text-gray--800'
            answersColor='text-grayscale--500'
            borderColor='#fff'
          />
          <div className='col-12 mb-lg-5 d-none d-md-block d-lg-none mt-5'>
            <button
              onClick={() => {
                setIsModalOpen(true)
                setSectionDetails({
                  sectionName: 'Perguntas frequentes',
                  dobra: 'dobra_08',
                })
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Abrir conta KIDS gratuita',
                  section_name: 'Perguntas frequentes',
                })
              }}
              className='btn btn--orange fs-14 fw-700 rounded-1'
            >
              Abrir conta KIDS gratuita
            </button>
          </div>
        </div>
      </div>
    </PerguntasFrequentesKids>
  )
}

export default PerguntasFrequentes
