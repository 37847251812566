import React from 'react'

// Components
import Icon from 'src/components/UI/MarkdownIcon'
import ImageWebp from 'src/components/ImageWebp'

// Style
import { CartaoDebito } from './style'

// Assets
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsModalOpen: Function;
  setSectionDetails: Function;
}

const CartaoPesonalizaNomeFilho = ({ setIsModalOpen, setSectionDetails }: modalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 336, 376, 456,
  ]

  const arrayHeight: number[] = [
    307, 331, 370, 449,
  ]

  return (
    <CartaoDebito id='cartao-de-debito' className='bg-yellow--extra-dark pb-5 py-md-5'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 cartao-personalizado'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-5-kids/image.webp'
              altDescription='Cartão personalizado Conta Digital Kids Banco Inter'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-lg-7'>
            <h2 className='fs-32 fs-md-40 text-blue--medium'>Cartão de débito</h2>
            <p className='fs-16 fs-md-18 lh-22 text-blue--medium pb-2'>Cartão personalizado com o nome do seu filho pra ele não precisar andar com dinheiro no bolso.</p>
            <div className='col-12 px-0 mb-4'>
              <div className='bg-white rounded-3 d-flex py-2 py-md-3 justify-content-center align-items-center'>
                <div className='ml-3'>
                  <Icon width='40' height='40' icon='pagamentos/cards' directory='v2' />
                </div>
                <div className='col-10 col-md-11'>
                  <h4 className='fs-16 lh-20 mb-0'>Cartão de débito Mastercard</h4>
                </div>
              </div>
            </div>
            <div className='col-12 px-0 mb-4'>
              <div className='bg-white rounded-3 d-flex py-2 py-md-3 justify-content-center align-items-center'>
                <div className='ml-3'>
                  <Icon width='40' height='40' icon='action/center-face' directory='v2' />
                </div>
                <div className='col-10 col-md-11'>
                  <h4 className='fs-16 lh-20 mb-0'>Nome da criança no cartão</h4>
                </div>
              </div>
            </div>
            <div className='col-12 px-0 mb-4'>
              <div className='bg-white rounded-3 d-flex py-2 py-md-3 justify-content-center align-items-center'>
                <div className='ml-3'>
                  <Icon width='40' height='40' icon='action/lock' directory='v2' />
                </div>
                <div className='col-10 col-md-11'>
                  <h4 className='fs-16 lh-20 mb-0'>Bloqueio pelo app em caso de perda, furto ou roubo</h4>
                </div>
              </div>
            </div>
            <div className='col-12 mt-4 px-0'>
              <button
                onClick={() => {
                  setIsModalOpen(true)
                  setSectionDetails({
                    sectionName: 'Cartão de débito',
                    dobra: 'dobra_05',
                  })
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Abrir conta KIDS gratuita',
                    section_name: 'Cartão de débito',
                  })
                }}
                className='btn btn--orange fs-14 fw-700 rounded-1'
              >
                Abrir conta KIDS gratuita
              </button>
              <span className='svg-title right d-none d-xl-block' />
            </div>
          </div>
        </div>
      </div>
    </CartaoDebito>
  )
}

export default CartaoPesonalizaNomeFilho
