import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background: ${white};
  min-height: 100vh;
  padding: 24px 30px 40px 30px;
  position: relative;

  img {
    width: 156px;
  }

  @media (min-width: ${breakpoints.md}) {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    max-width: 360px;
    min-height: 375px;
    top: 20vh;
    border-radius: 8px;
  }

  @media (min-width: ${breakpoints.lg}) {
    top: 15vh;
  } 
`

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 10;
  outline: none !important;
`
