import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'
import TitleOrange from './../../assets/images/svg-title-orange.svg'

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: calc(100vh - 64px);

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .scroll-link {
    bottom: 20px;
    fill: orange;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }

  .fixed-bread {
    .bread-crumb {
      span {
        color: ${gray['700']} !important;
        a {
          color: ${gray['700']};
          font-weight: 900;
        }  
      }
    }
  }

  h1 {
    @media ${device.tablet} {
      &::before {
        display: none;
      }
    }

    @media ${device.desktopXL} {
      &::before {
        content: '';
        display: block;
        background: url(${TitleOrange}) no-repeat;
        height: 89px;
        left: -90px;
        position: absolute;
        top: 15px;
        width: 98.07px;
      }
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 344px;
    }

    @media ${device.desktopLG} {
      max-width: 372px;
    }
  }
`
