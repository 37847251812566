import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import TitleYellow from './../../assets/images/svg-title-yellow.svg'

export const ComoAbrirConta = styled.section`

  a {
    text-decoration: underline;
  }

  @media ${device.tablet} {
    min-height: 700px;
  }

  h2 {
    &::after {
      content: '';
      background: url(${TitleYellow}) no-repeat;
      height: 53px !important;
      position: absolute;
      right: 0;
      top: 20px;
      width: 47px !important;

      @media ${device.tablet} {
        margin-left: 30px;
        right: auto;
        top: 30px;
      }

      @media ${device.desktopLG} {
        margin-left: 50px;
        top: 0;
      }
    }
  }

  .detail {
    margin-top: -89px;

    @media ${device.tablet} {
      margin-left: 40px;
    }

    @media ${device.desktopLG} {
      margin-left: 0;
      margin-top: -105px;
    }
  }
  
  .react-multi-carousel-list {
    @media ${device.tablet} {
      padding-bottom: 50px;
    }
    @media ${device.desktopLG} {
      padding-bottom: 30px;
    }
  }

  .react-multi-carousel-dot-list {
    width: 230px;
    margin: 0 auto;

    .react-multi-carousel-dot {
      button {
        background-color: rgba(255, 255, 255, 0.5)!important;
      } 
    }
    .react-multi-carousel-dot--active {
      button {
        background-color: #FF8700!important;
      } 
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 25px;
    @media ${device.tablet} {
      padding: 30px 0 5px;
    }
    @media ${device.desktopLG} {
      padding: 10px 0 10px;
    }
  }
  .react-multiple-carousel__arrow--right {
    right: 0;
    @media ${device.tablet} {
      right: 170px!important;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -11px;
    @media ${device.tablet} {
      left: 139px!important;
    }
  }

  .text-content {
    min-height: 180px;
  }
`
export const ImgNumberSize = styled.div`
  .gatsby-image-wrapper {
    width: 40px;;
  }
`

export const ImgAppSize = styled.div`
  .gatsby-image-wrapper {    
    width: 170px;

    @media ${device.tablet} {
      width: 176px;
      margin: 0 auto;
    }
  }
`
