import React from 'react'

// Components
import Icon from 'src/components/UI/MarkdownIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'

// Style
import { BlogCarousel } from './style'

// Assets
import BlogArticlesKidsAccountJSON from '../../assets/data/BlogArticlesKidsAccount.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ItensProps = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const DicasParaInvestirMelhor = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 294, 291, 355,
  ]

  const arrayHeight: number[] = [
    243, 241, 248, 269,
  ]

  return (
    <section id='dicas-para-investir-melhor' className='bg-white d-flex pb-md-5 pb-lg-0'>
      <div className='container pb-5 pb-lg-0'>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              { BlogArticlesKidsAccountJSON.map((item: ItensProps, index: number) => (
                <a
                  key={index}
                  data-blog={item.title}
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  title={`Leia mais em ${item.link}`}
                  className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'null',
                      element_action: 'click button',
                      element_name: item.title,
                      redirect_url: item.link,
                    })
                  }}
                >
                  <BlogCarousel className='col-12 p-card mt-md-3'>
                    <article className='col-12 px-0'>
                      <ImageWebp
                        pathSrc={item.image}
                        altDescription={item.alt}
                        arrayNumbers={arrayNumbers}
                        arrayNumbersHeight={arrayHeight}
                      />
                      <div className='col-12 pt-3 rounded-5 px-4 px-md-3 px-lg-4 mt-n5 bg-white'>
                        <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                        {item.secundaryTag ? <p className='fs-12 lh-15 fw-600 text-orange--extra bg-gray-400 rounded-1 mb-1 px-2 secundary-tag'>{item.secundaryTag}</p> : ''}
                        <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                        <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                        <p className='fs-14 fs-lg-16 lh-19 lh-md-19 mb-2 fw-400 d-none d-lg-block mt-3 text-grayscale--400'>{item.description}</p>
                        <span className='text-right d-none d-lg-block'>Leia mais
                          <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                        </span>
                      </div>
                    </article>
                  </BlogCarousel>
                </a>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DicasParaInvestirMelhor
