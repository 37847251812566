import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import TitleOrange from './assets/images/svg-title-orange.svg'
import TitleYellow from './assets/images/svg-title-yellow.svg'
import TitleOrangeRight from './assets/images/svg-title-orange-right.svg'

export const Wrapper = styled.div`
  h2 {
    margin-bottom: 20px;
  }

  .svg-title {
    background: url(${TitleOrange}) no-repeat;
    background-position: center left;
    display: flex;
    height: 89px;
    width: 98.07px;

    &.yellow {
      @media ${device.desktopLG} {
        background: url(${TitleYellow}) no-repeat;
        height: 53px !important;
        left: -100px !important;
        position: absolute;
        width: 47px !important;
      }
    }

    &.right {
      background: url(${TitleOrangeRight}) no-repeat;

      @media ${device.tablet} {
        &.right {
          display: none;
        }
      }
    }
  }
`
